import {ApiResponse, http} from "@/common/http";
import {AxiosResponse} from "axios";
import {CustomerContact, CustomerInfo, CustomerSettings, DepartmentReference} from "@/app/customer/Customers";
import {AppStateCustomer} from "@/app/store";

export const getCustomerContacts = async (customerId: string): Promise<CustomerContact[]> => {
    const {data} = await http.get<null, AxiosResponse<CustomerContact[]>>(
        `/customers/${customerId}/contact_person`
    );

    return Promise.resolve(data);
};

export const getCustomerDepartments = async (customerId: string): Promise<DepartmentReference[]> => {
    const {data} = await http.get<null, AxiosResponse<DepartmentReference[]>>(
        `/customers/${customerId}/departments`
    );

    return Promise.resolve(data);
};

export const saveCustomerContact = async (
    customerId: string,
    contact: CustomerContact
): Promise<CustomerContact[]> => {
    const {data} = await http.put<
        CustomerContact,
        AxiosResponse<CustomerContact[]>>(`/customers/${customerId}/contact_person/${contact.id}`, contact);

    return Promise.resolve(data);
};

export const updateLastSelectedDepartment = async (departmentId: string): Promise<any> => {
    const {data} = await http.put<
        null,
        AxiosResponse<any>>(`/change_last_selected_department/${departmentId}`);

    return Promise.resolve(data);
};

export const changeCustomerSettings = async (settings: CustomerSettings): Promise<any> => {
    const {data} = await http.put<
        CustomerSettings,
        AxiosResponse<any>>(`/change_settings`, settings);

    return Promise.resolve(data);
};

export const getCustomerInfo = async (): Promise<CustomerInfo> => {
    const {data} = await http.get<null, AxiosResponse<CustomerInfo>>(
        `/customer_info`
    );

    return Promise.resolve(data);
};

export const getCustomerInfoById = async (customerId: string): Promise<CustomerInfo> => {
    const {data} = await http.get<null, AxiosResponse<CustomerInfo>>(
        `/customer_info/${customerId}`
    );

    return Promise.resolve(data);
};

export const findCustomers = async (query: string): Promise<ApiResponse<AppStateCustomer>> => {
    const {data} = await http.post<any, AxiosResponse<ApiResponse<AppStateCustomer>>>(
        `/customers`,
        {query: query}
    );

    return Promise.resolve(data);
};